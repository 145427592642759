import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Our Story" mdxType="SEO" />
    <h1 {...{
      "id": "our-story"
    }}>{`Our Story`}</h1>
    <h2 {...{
      "id": "the-future-of-affordable-energy-efficient-housing"
    }}><strong parentName="h2">{`The future of affordable, energy-efficient housing`}</strong></h2>
    <p>{`Since its inception more than a century ago, on-site solar energy collection, storage and utilisation has faced limitations in terms of feasibility and reliability. Motivated to develop a better design for the technology, Fluid Solar’s founder Roger Davies began researching and developing a series of energy technologies in 2008 for on-site solar thermal energy collection, storage and delivery to homes and businesses. Key components including new methods of solar thermal collection, solar powered heating, cooling and air conditioning technologies have now been successfully developed, patented in Australia, Japan, China, the USA, Canada, India and Europe, and have been operating successfully for over 2 years at Fluid Solar House, the first off-grid solar thermal powered multi-storey building in the world. Improvement and refinement of these systems continues. All these technologies can be integrated with traditional energy solutions for optimum, sustainable, low carbon living. For an overview of how our solar thermal energy collection works, please see Our Technologies.`}</p>
    <p>{`The team at Fluid Solar have been focused since 2015 on bringing the Fluid Solar thermal energy suite of technologies to the consumer and their businesses. A range of affordable, rapid installation, energy-efficient and sustainable building designs are being developed and fabricated in South Australia from locally manufactured components. These homes, additions or commercial spaces function efficiently and reliably with renewably-sourced Fluid Solar energy systems, which produce zero carbon emissions while operating. In 2017, Fluid Solar House was completed–the world’s first solar thermal hybrid off-grid office building–demonstrating with resounding success the viability of local solar thermal energy for powering commercial buildings.`}</p>
    <p>{`More commercial building projects have followed, including 20 two-storey town houses now under construction in Northern Adelaide and the installation of single-storey free-standing units for short term accommodation at Gawler, South Australia. The Fluid Solar modular, prefabricated construction method provides an opportunity to tackle the dual challenges of greenhouse gas emissions and housing affordability, and bring sustainable, attainable housing opportunities to Australians and people around the world.`}</p>
    <h2 {...{
      "id": "meet-our-team"
    }}>{`Meet our team`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "263px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4b941ae15baf657daddbd65adc57d893/28fcc/roger.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUCA//EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABqRsqsIxHloCmDb//xAAcEAACAgIDAAAAAAAAAAAAAAABAgADBBIUMTP/2gAIAQEAAQUC2V5kFxYgJRLmVrX3nOeDuzyn/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBL/2gAIAQMBAT8BijI6/8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBJR/9oACAECAQE/AW+GiNf/xAAcEAACAQUBAAAAAAAAAAAAAAAAARECICEyQVH/2gAIAQEABj8CwzqFPhKRNWyOWf/EAB0QAQADAAEFAAAAAAAAAAAAAAEAESFREDFhgbH/2gAIAQEAAT8hGVtLYNeGxx7kXNEUIhKjR4lvD1NG+WPPm/vT/9oADAMBAAIAAwAAABA/KPz/xAAXEQADAQAAAAAAAAAAAAAAAAABESAh/9oACAEDAQE/ENS4P//EABYRAQEBAAAAAAAAAAAAAAAAABARMf/aAAgBAgEBPxCsHB//xAAcEAEBAQEAAwEBAAAAAAAAAAABESEAMUFhEFH/2gAIAQEAAT8QctYWSU/vMiaxgfs99S5YgmzeBDSKub74LRMjRzFMSQ7m+G32eLzRzDX3+P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "roger",
            "title": "roger",
            "src": "/static/4b941ae15baf657daddbd65adc57d893/28fcc/roger.jpg",
            "srcSet": ["/static/4b941ae15baf657daddbd65adc57d893/28fcc/roger.jpg 263w"],
            "sizes": "(max-width: 263px) 100vw, 263px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<strong parentName="p">{`Roger Davies`}</strong>{` Managing Director`}</p>
    <p>{`Roger Davies’ interest in renewable energy was sparked by a love of flying, looking at engines and waste heat – which gradually evolved into the research of solar thermal systems, and how they could be used to solve the challenge of affordable and sustainable housing.`}</p>
    <p>{`In addition to Fluid Solar, Roger is also the Director of Adelaide MRI. A qualified Radiologist, Roger has a strong background in the medical sciences, and his innovative approach is also evident in this field through the development of advanced radiology reporting software.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "263px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/834085f0cf64559c73100abe46e7b766/28fcc/photi.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQFAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAByaprQ8wIdWG0Ar//xAAbEAACAwADAAAAAAAAAAAAAAACAwEQEgAhMf/aAAgBAQABBQJIbNyoCkFljuqH1xyQ8//EABYRAQEBAAAAAAAAAAAAAAAAABEBIP/aAAgBAwEBPwGBj//EABYRAAMAAAAAAAAAAAAAAAAAAAEgMf/aAAgBAgEBPwE1P//EABoQAAICAwAAAAAAAAAAAAAAAAEQAAIRMYH/2gAIAQEABj8CAYzqFCV6v//EAB0QAQACAQUBAAAAAAAAAAAAAAEAESEQMUFhkcH/2gAIAQEAAT8hhtJTGC7oZU8vkXMHpCJotTRvnT//2gAMAwEAAgADAAAAEN/4QP/EABYRAQEBAAAAAAAAAAAAAAAAAAERIP/aAAgBAwEBPxAp3H//xAAWEQEBAQAAAAAAAAAAAAAAAAABESD/2gAIAQIBAT8QTGP/xAAcEAEBAQACAwEAAAAAAAAAAAABEQAxQSFxsfD/2gAIAQEAAT8QBFg1ceCbhHDsK5uhzRUTE7j9MakxcXI+4ai8SKIL6yF3/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "photi",
            "title": "photi",
            "src": "/static/834085f0cf64559c73100abe46e7b766/28fcc/photi.jpg",
            "srcSet": ["/static/834085f0cf64559c73100abe46e7b766/28fcc/photi.jpg 263w"],
            "sizes": "(max-width: 263px) 100vw, 263px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<strong parentName="p">{`Photi Kazis`}</strong>{` Architect`}</p>
    <p>{`Photi is an architect with 29 years experience in the industry. In the 1990s he joined the 3D simulation revolution and branched out to architectural animation, with a brief foray into theatrical animation, before refocussing on architecture and joining Fluid Solar on its landmark building and energy efficiency projects. He is currently putting his talents to use on projects as diverse as low cost housing to medium scale commercial, with energy efficiency and building longevity being a key focus in the design process.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "263px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2d5b870bc33660e9c44e9bcb923345d6/28fcc/rhys.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAEDAgQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIDAP/aAAwDAQACEAMQAAAB3pnnhr2LCykSkCsv/8QAGhABAQADAQEAAAAAAAAAAAAAAQIDEiEAIv/aAAgBAQABBQICKrLOzz2XhsjT3ZST6vt//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACEP/aAAgBAwEBPwEOYlb/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAgEBPwGpGI//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAQEiExUYH/2gAIAQEABj8CZ4iDrvUyAdeUZ//EABwQAAMAAgMBAAAAAAAAAAAAAAABESExQWGBcf/aAAgBAQABPyFC2lwR0W+imlY0sfAlJo9UoNnPArxoXNbw4f/aAAwDAQACAAMAAAAQIMD9/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAERIUGBsf/aAAgBAwEBPxCRFmG+IScJn//EABoRAQEAAgMAAAAAAAAAAAAAAAEAESFBgbH/2gAIAQIBAT8QXFq5desIbL//xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMUFRYYH/2gAIAQEAAT8QcZ0hpZqa64r92ZfIPMRLZcL9bJjdHh6VlepYoIIdPkGbrvAW2SxKyp8XEUDADWT/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rhys",
            "title": "rhys",
            "src": "/static/2d5b870bc33660e9c44e9bcb923345d6/28fcc/rhys.jpg",
            "srcSet": ["/static/2d5b870bc33660e9c44e9bcb923345d6/28fcc/rhys.jpg 263w"],
            "sizes": "(max-width: 263px) 100vw, 263px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<strong parentName="p">{`Rhys Heard`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "263px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e179a45ba2c13a26d78012e79aa46dec/28fcc/louis.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAQBAwUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAG7l0zD1WxO8pkuaBn/xAAcEAEBAAEFAQAAAAAAAAAAAAABAwIABBAREjH/2gAIAQEAAQUC3Kk5ejN+3tPqdTBxvJODX//EABcRAAMBAAAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8Bwr//xAAXEQADAQAAAAAAAAAAAAAAAAAAEBIh/9oACAECAQE/AdJf/8QAHBAAAgICAwAAAAAAAAAAAAAAEBEBAgASITEy/9oACAEBAAY/AnW3eOLMaxyPSED/xAAcEAEBAAICAwAAAAAAAAAAAAABABEhYYExQVH/2gAIAQEAAT8ha4SsasIrg7gCBXHwjJevqGLt4sv6mY1PC//aAAwDAQACAAMAAAAQkxfA/8QAFhEBAQEAAAAAAAAAAAAAAAAAASAx/9oACAEDAQE/EA1D/8QAFxEBAAMAAAAAAAAAAAAAAAAAEQABEP/aAAgBAgEBPxBBcW//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMWFxsf/aAAgBAQABPxClCSRWUq35CjG9dsOLBSGNM6gF06uheyzapwcXCFVuQRuJWGyt0H7G1n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "louis",
            "title": "louis",
            "src": "/static/e179a45ba2c13a26d78012e79aa46dec/28fcc/louis.jpg",
            "srcSet": ["/static/e179a45ba2c13a26d78012e79aa46dec/28fcc/louis.jpg 263w"],
            "sizes": "(max-width: 263px) 100vw, 263px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Louis Chung`}</strong>{` Mechanical Engineer`}</p>
    <p>{`Louis attained his Bachelor’s of Engineering (Honours) in Mechanical Engineering from Sydney’s University of Technology. With his passion for problem solving, together with a strong belief in sustainable development, working to overcome the inherent challenges in off-grid technologies with Fluid Solar is a natural fit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      